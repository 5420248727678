// variable definitions
:root {
  --off-white: hsl(34, 78%, 95%); /* lighter antiquewhite */
  --off-black: hsl(34, 78%, 10%);
  --darker-gray: #666; /* TODO: better */
  --lighter-gray: #BBB;

  --link-color: blue;
  --link-visited-color: purple;
  --text-color: var(--off-black);
  --secondary-text-color: var(--darker-gray);
  --background-color: var(--off-white);
}

@media (prefers-contrast: more) {
  :root {
    --text-color: black;
    --secondary-text-color: black;
    --background-color: white;
  }
}

// selection mixin
@mixin selectable {
  user-select: text;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
}

html {
  height: 100%;
  font-family: monospace;
  font-size: 12pt;
  background-color: var(--lighter-gray);
}

p, h1, h2, h3, h4, h5, h6, ul, ol, pre, blockquote {
  margin: 0;
}

hr {
    color: var(--text-color);
    border: none;
    // TODO: border-image of svg of `-`
    // border-top: dashed 0.1rem;
    text-align: center;
    font-family: monospace;
    font-size: 12pt;
    margin: 0;
    &::after {
        position: relative;
        content: "--------------------------------------------------------------------------------";
    }
}

hr.snip::after {
    content: "--------------------------------------- 8< -------------------------------------";
}

hr.double:after {
    content: "================================================================================";
}

hr.dotted:after {
    content: "********************************************************************************";
}

pre {
  font-family: inherit;
}

body {
  color: var(--text-color);
  background-color: var(--background-color);
  width: 80ch;
  max-width: calc(100vw - 1ch);
  padding: 1rem 0.5ch;
  margin: 0 auto;
  overflow-x: hidden;  // cutoff "---" lines (everything else should wrap)

  min-height: calc(100% - 2rem);
  // css grid
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  // grid-row-gap: 1rem;

}

// max-width body, detached style
@media (min-width: calc(80ch + 96px)) {
    body {
        // matches holes in image
        box-shadow: 10px 10px 1px 0px var(--darker-gray);
    }

    // pop paper/body out
    html {
        padding-top: 1rem;
        // fill screen exactly if possible
        height: calc(100% - 1rem);
    }
}

// border visible but filling screen
@media (min-width: calc(30ch)) {
    body {
        // Ugh, in this scss version the bultin min() doesn't support mixed
        // units and throws an error instead of passing it on to the browser.
        // Luckily, it's case-sensitive and the browser isn't...
        --border-width: MIN(48px, 7vw);
        max-width: calc(100vw - 1ch - (2 * var(--border-width));
        padding: inherit 0;

        border-image-source: url("paper-border.svg");
        border-image-slice: 0% 50%;
        // "space" prevents layout switching drastically between different page sizes.
        // Ideally "repeat" could be used but it splits the overflow between between both ends instead of only the bottom.
        border-image-repeat: space;
        // unitless values are multiples of border-width
        border-image-width: 0 1;

        // Border is required for border-image to show.
        // The width of border is used by border-image, overlaps with content if too small.
        // Fallback border temporarily flashes while loading image, so use same color as background
        border-style: dotted;
        border-color: var(--background-color);
        border-width: 0 var(--border-width);
    }
}

body > header { // site header
  nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > a {
      padding-right: 1ch;
      flex: 1 0 auto;
    }
  }
  pre {
    overflow: hidden;
  }
}

body > footer {  // site footer
  text-align: center;
  pre {
      overflow: hidden;
  }
}

body > main > article.post {  // main content for posts
  header {
    h1 {
      font-size: 3rem;
    }
    .meta {
      margin-top: 0.5rem;
      font-size: 1.25rem;
    }
  }

  > section {
    font-family: initial;

    h1 { font-size: 2.5rem }

    h1, h2, h3, h4, h5, h6 {
        font-family: monospace;
    }

    h1, h2, h3, h4, h5, h6, p, pre, blockquote {
      margin-top: 1rem;
    }

    p, ul, ol {
      text-align: justify;
    }

    pre {
      padding: 1rem; /* Using padding instead of margin moves the scrollbar out of the way. */
      font-family: monospace;  // undo default serif for pre-formatted
      overflow-x: auto; // scroll means always show a scroll bar, even if there is no overflow
      code {
        // interesting options: https://developer.mozilla.org/en-US/docs/Web/CSS/white-space
        white-space: pre;
      }
    }

    /* Forcefully ignore inserted theme background */
    div.highlight > pre:has(> code) {
      background-color: var(--background-color) !important;
    }

    blockquote {
      padding: 0 1rem;
      border-left: solid 0.25rem;
      color: var(--secondary-text-color);
      font-style: italic;
      text-align: left;
    }

    ul, ol {
      padding-left: 2rem;
    }

    img, video {
      max-width: 100%;
      height: auto;
      max-height: 90vh;
    }

    // markdown ![]() images
    p > img, video {
      // center horizontally
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    figure {
      figcaption {
        margin-top: 0.25rem;
        text-align: center;
        font-style: italic;
      }
    }

    div.video {
      position: relative;
      padding-bottom: 50.625%;
      height: 0;
      overflow: hidden;
      > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border:0;
      }
    }

    figure, div.video {
      margin: 1rem 5%;
    }
  }
}

ul.inline {
  display: inline;
  padding: 0;
  list-style-type: none;
  li {
    user-select: all;
    -moz-user-select: text;
    display: inline;
  }
  li::after {
    -moz-user-select: text;
    content: " | "
  }
  li:last-child::after {
    content: none;
  }
}

a {
  color: unset;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--link-color);
}
a:hover {
  color: var(--link-color);
  text-decoration-style: solid;
}
a:visited {
  color: unset;
  text-decoration-color: var(--link-visited-color);
}
a:visited:hover {
  color: var(--link-visited-color);
}

a.hidden {
  color: unset;
  text-decoration: none;
  border: none;
}
a.hidden:visited {
  color: unset;
}

.struck {
  text-decoration: line-through;
  text-decoration-color: red;
}

span.labels {
  a {
    color: unset;
    text-decoration-color: unset;
    text-decoration-line: none;
  }
  a:hover {
    color: inherit;
    text-decoration-line: underline;
  }
  .software {color: blue}
  .mechanical {color: red}
  .electrical {color: orange}
  .art {color: fuchsia}
  .olin {color: #009BDF}
  span {
      @include selectable();
  }
  span::after {
      content: ";";
      color: var(--text-color);
      @include selectable();
  }
  span:last-child::after {
      content: none;
  }
}

main {
  margin-top: 1rem;
}

span.date::after {content: ":"}
span.type::before {content: "("}
span.type::after {content: ")"}
span.categories::before {content: "["}
span.categories::after {content: "]"}
span.tags::before {content: "{"}
span.tags::after {content: "}"}

#no2web3 {
    display: inline-block;
    vertical-align: bottom;
    width: 18ch;
    overflow: hidden;
}

#no2web3:hover, #no2web3:focus-within {
    // box-sizing: border-box;
    background-color: black;
    > span {
      color: limegreen;
      text-transform: uppercase;
      display: inline-block;
      // box-sizing: border-box;
      animation: marquee 5s linear 0.5s infinite;
    }
}

@keyframes marquee {
    0% { transform: translate(0, 0); }
    50% { transform: translate(-100%, 0); }
    50.001% { transform: translate(100%, 0); }
}
